
import { Route } from "react-router-dom";

import ToolsPage from "./toolsPage";
import React, { Component } from "react";
import CalculatorMainapp from "./Calculator/calculatorMainapp";
import Header from "../header";


export default class ToolsMainapp extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <div>
            <div className="row">
            <Header/>
          
            <div className="col-md-12">
            <div className="section">
            <Route exact path="/organisations/:organisationName/tools" component={ToolsPage}/>
            <Route exact path="/organisations/:organisationName/tools/calculator" component={CalculatorMainapp}/>
            </div> 
            </div> 
            </div>
           
            
            </div>
        )
    }
}


