import Amplify from "aws-amplify";
import axios from "axios";
import $ from "jquery";
import React from "react";
import { Auth } from 'aws-amplify';
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import config from "./awsconfig";
import AccountInfoMainapp from "./components/AccountInfoMainapp";
import AuthScreen from "./components/Auth";
import MainScreen from "./components/mainScreen";
import OrganisationScreen from "./components/organisationScreen";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import MainApp from "./components/mainApp";
import "./style/panel.css";
import resourcesMainapp from "./components/Resources/resourcesMainapp";
import TaskMainApp from "./components/taskMaster/TaskMainapp";
import LogsMainApp from "./components/logs/LogsMainapp";
import IssueMainapp from "./components/issues/IssueMainapp";
import workflowMainapp from "./components/workflowMaster/workflowMainapp";
import AssignmentMainapp from "./components/Assignment/AssignmentMainapp"
import PageAssignment from "./components/Assignment/pageAssignment";
import communicationMainapp from './components/communication/communicationMainapp';
import BusinusSchoolDashboard from "./components/BusinusSchool/businusSchoolDashboard";
import dedicatedMailPage from "./components/dedicatedmailpage";
import BookmarkDashboard from "./components/BusinusSchool/Bookmark/bookmarkDashboard";
import ListBookmark from "./components/BusinusSchool/Bookmark/listBookmark";
import AppointmentMainApp from "./components/Appointment/AppointmentMainApp";
import BusinusSchoolScreen from "./components/businusSchoolScreen";
import CalendarMainapp from "./components/Calendar/calendarMainapp";
import SmartDocMainapp from "./components/Smart_Docs/smartDocMainapp";
import ToolsMainapp from "./components/Tools/toolsMainapp";
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "testApiCall",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

axios.interceptors.request.use(
  function (request) {
    console.log("request intercepted");
    request.headers["Authorization"] = sessionStorage.token;

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

$(function () {
  $("#tab1").click(function () {
    $("#section").toggle();
  });
});
/*  $(function(){
    $("#c2").click(function(){
      $("#d2").toggle();
    });
    });
    $(function(){
      $("#c3").click(function(){
        $("#d3").toggle();
      });
      });
    $(function(){
      $("#c4").click(function(){
          $("#d4").toggle();
        });
        });
    $(function(){
       $("#c5").click(function(){
        $("#d5").toggle();
          });
          });
    $(function(){
        $("#c6").click(function(){
        $("#d6").toggle();
            });
            });
    $(function(){
        $("#c7").click(function(){
        $("#d7").toggle();
              });
              });
    $(function(){
        $("#c8").click(function(){
        $("#d8").toggle();
                });
                });   */

axios.interceptors.request.use(
  function (request) {
    console.log("request intercepted");
    request.headers["Authorization"] = sessionStorage.token;

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

async function refreshToken(){
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  const session = await Auth.currentSession().catch((err)=>{
});
console.log(session.getAccessToken().getJwtToken());
const token=session.getAccessToken().getJwtToken()
  localStorage.setItem("token", token) ;
  console.log("token refreshed");
};


window.lastActivity = Date.now();
 document.addEventListener('click', function(){
    window.lastActivity = Date.now();
    console.log("user clicked at :", window.lastActivity);

}) 
var TIMEOUT = 1500000 //25 mins
var activityChecker = setInterval(check, 180000); //3mins

/* function createAlert(){
if(TIMEOUT==10000){
  var text="";
  if (confirm("Continue the session!") == true) {
    text = "You pressed OK! ";
  } else {
    text = "You canceled!";
  }
}} */

function check() {
  console.log("check function started")
    var currentTime = Date.now();
    console.log("current time:", currentTime)
    if(localStorage.isLoggedIn==="true" ){


    
    if (currentTime - window.lastActivity > TIMEOUT  ) {
      
    
        var text="";
        if (window.confirm("Please press OK to continue the session else the session will expire soon!" ) == true) {
          text = "You pressed OK! ";
          refreshToken();
          console.log("refreshing Token")
        } else {

          
          text = "You canceled!";
       localStorage.clear();
          alert("session has expired")
          window.location.replace("/") 
        }
    


  
        // do something useful, for example logout or whatever
    }
    else {
     

    }
}}

ReactDOM.render(
  <div>
    <BrowserRouter>
      <AuthScreen />
      <Route exact path = "/home" component={OrganisationScreen}/> 
      <Route exact path="/organisations" component={OrganisationScreen} />
      <Route exact path="/user/info" component={AccountInfoMainapp} />
      <Route exact path="/user/info/account" component={AccountInfoMainapp} />
      <Route exact path="/user/info/settings" component={AccountInfoMainapp} />
      <Route
        path="/organisations/:organisationName/tasks"
        component={TaskMainApp}
      />
         <Route
        path="/organisations/:organisationName/logs"
        component={LogsMainApp}
      />
       <Route
        path="/organisations/:organisationName/issues"
        component={IssueMainapp}
      />
       <Route exact
    
      path="/organisations/businusSchool"
      component={BusinusSchoolDashboard}
      />

<Route
      exact
      path="/organisations/businusSchool/bookmarks"
      component={BookmarkDashboard}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/legal-finance"
      component={ListBookmark}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/exim"
      component={ListBookmark}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/opportunity-growth"
      component={ListBookmark}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/stories-insights"
      component={ListBookmark}
      />
      {/* <Route exact
        path="/organisations/businusSchool"
        component={BusinusSchoolScreen}
      />
      
      <Route exact
        path="/organisations/businusSchool/appointment"
        component={AppointmentMainApp}
      />
            <Route exact
        path="/organisations/businusSchool/appointment-consultant/view-appointment"
        component={AppointmentMainApp}
      />
            <Route exact
        path="/organisations/businusSchool/appointment-consultant/view-appointment/:id"
        component={AppointmentMainApp}
/>*/}

     <Route exact path="/smartdoc/add" component={SmartDocMainapp} />
     <Route exact path="/organisations/:organisationName/calendar" component={CalendarMainapp}/>
  
       <Route
        path="/organisations/:organisationName/smartdoc"
        component={SmartDocMainapp}
      />
      <Route
        path="/organisations/:organisationName/tools"
        component={ToolsMainapp}
      />
      <Route
        path="/organisations/:organisationName/communication"
        component={communicationMainapp}
      />
      
      <Route
        path="/organisations/:organisationName/resources"
        component={resourcesMainapp}
      />
      <Route
        exact
        path="/organisations/:organisationName"
        component={MainScreen}
      />

      <Route
        exact
        path="/organisations/:organisationName/services"
        component={MainApp}
      />

      <Route
        exact
        path="/organisations/:organisationName/services/products/product-requests"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/products/queries-sent"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/consulting/proposals-requested"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/consulting/queries-sent"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/vetting-requests"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/tailoring-requests"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/instant-downloads"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/queries-sent"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-mails"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-proposals"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-updates"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-documents"
        component={MainApp}
      />

      <Route
        exact
        path="/organisations/:organisationName/services/products/all-queries-sent"
        component={MainApp}
      />

      <Route
        exact
        path="/organisations/:organisationName/services/products/product-requests/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/products/queries-sent/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/consulting/proposals-requested/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/consulting/queries-sent/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/vetting-requests/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/tailoring-requests/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/instant-downloads/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/docs/queries-sent/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/all-queries-sent/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-mails/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-updates/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-documents/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/services/master-proposals/:id"
        component={MainApp}
      />
      <Route
        exact
        path="/organisations/:organisationName/mail/:id"
        component={MainApp}
      />
      <Route
        
        path="/organisations/:organisationName/assignment"
        component={AssignmentMainapp}
      />
       <Route
        
        path="/organisations/:organisationName/communication/view/recieved/:mailType"
        component={communicationMainapp}
      />

<Route exact path="/organisations/:organisationName/assignment/view-assignment/:id" component={PageAssignment} />
       <Route
        
        path="/workflow"
        component={workflowMainapp}
      />

     

      <Route
      exact
      path="/organisations/businusSchool/bookmarks"
      component={BookmarkDashboard}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/legal-finance"
      component={ListBookmark}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/exim"
      component={ListBookmark}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/opportunity-growth"
      component={ListBookmark}
      />

      <Route
      exact
      path="/organisations/businusSchool/bookmarks/stories-insights"
      component={ListBookmark}
      />
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
