import React, { useState, useEffect } from "react";
// import vSmartDoc from "./viewSmartDoc.json";
import ReactExport from "react-export-excel";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../apiUtils";
import BreadCrumbs from "../BreadCrumbs";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
];

const dataSet2 = [
  {
    name: "Johnson",
    total: 25,
    remainig: 16,
  },
  {
    name: "Josef",
    total: 25,
    remainig: 7,
  },
];

function ViewSmartDoc() {
  const [vSmartDoc, setAllDocs] = useState(null);
  useEffect(() => {
    const getAllDocs = async () => {
      const { data } = await axios
        .get(" https://vdz44wruzh.execute-api.us-east-1.amazonaws.com/dev/businusquery/SmartDocs/"+localStorage.spId+"/" + localStorage.getItem("userId"))
        .catch((err) => console.log("ERR: " + err));
      setAllDocs(data);
    };
    getAllDocs();
  }, []);

  const columns = [
    {
      Header: (props) => (
        <span className="form-textHead cell-head">SmartDoc Id</span>
      ),
      accessor: (d) => {
        return {
          smartDoc: d.smartDocId,
          id: d.smartDocId,
        };
      },
      filterMethod: (filter, row) => {
        return row.name.name.includes(filter.value);
      },

      id: "smartDoc",
      Cell: (props) => (
        <Link className="link" to={"./view/" + props.value.id}>
          <span>{props.value.smartDoc}</span>
          {/* <span>{i}</span> */}
        </Link>
      ),
      minWidth: 180,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">SmartDoc Name</span>
      ),
      accessor: (d) => {
        return { smartDoc: d.docName, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.name.includes(filter.value);
      },

      id: "smartDocName",
      Cell: (props) => <span>{props.value.smartDoc}</span>,
      minWidth: 180,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">SmartDoc Overview</span>
      ),
      accessor: (d) => {
        return { smartDoc: d.docOverview, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.name.includes(filter.value);
      },

      id: "smartDocOverview",
      Cell: (props) => <span>{props.value.smartDoc}</span>,
      minWidth: 180,
    },

    // {
    //   Header: (props) => (
    //     <span className="form-textHead cell-head">SmartDoc Overview</span>
    //   ),
    //   accessor: "overview",
    //   Cell: (props) => <span className="form-text cell">{props.value}</span>,
    //   minWidth: 240,
    // },

    {
      Header: (props) => (
        <span className="form-textHead cell-head">Date of Creation</span>
      ),
      accessor: (d) => {
        return { date: d.date, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.assignmentTitle.includes(filter.value);
      },

      id: "date",
      Cell: (props) => new Date(props.value.date).toDateString(),
      minWidth: 180,
    },
    {
      Header: (props) => (
        <span className="form-textHead cell-head">Time of Creation</span>
      ),
      accessor: (d) => {
        return { date: d.date, id: d.id };
      },
      filterMethod: (filter, row) => {
        return row.name.assignmentTitle.includes(filter.value);
      },

      id: "time",
      Cell: (props) => new Date(props.value.date).toLocaleTimeString(),
      minWidth: 280,
    },
  ];

  return (
    <div>
      <div className="Div">
        <div className="row breadcrumb-row">
          <div className="col-md-10">
            <BreadCrumbs />
            {/* {vSmartDoc && JSON.stringify(vSmartDoc)} */}
          </div>
          <div className="col-md-2">
            <ExcelFile
              element={
                <button className="download-button-wrapper">
                  Download Data in Excel format
                </button>
              }
            >
              <ExcelSheet data={dataSet1} name="WorkflowList">
                {dataSet1.map((d) => (
                  <ExcelColumn label={d.name} value={d.sex} />
                ))}
              </ExcelSheet>

              <ExcelSheet data={dataSet2} name="Mails">
                {dataSet2.map((d) => (
                  <ExcelColumn label={d.name} value={d.total} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
        <div className="row">
          <Row>
            <Col>
              <div className="card">
                
                  <ReactTable
                    className="myTable"
                    resolveData={(data) => data.map((row) => row)}
                    data={vSmartDoc||[]}
                    columns={columns}
                    filterable
                  />
                
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ViewSmartDoc;
