import React, { Component } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl5 } from "../apiUtils";
import { apiBaseUrl2 } from "../apiUtils";
import { ToastsStore } from "react-toasts";
import BreadCrumbs from "../BreadCrumbs";
import $ from "jquery";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import ReactTableUI from "./ReactTableUI";

import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { getPrivilegeByName } from "../../privilegeUtils";

import ReactExport from "react-export-excel";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const colformail = [
  {
    id: "subject",
    label: "subject",
  },
  { id: "message", label: "message" },
  {
    id: "attachment",
    label: "attachment",
  },
  {
    id: "from",
    label: "from",
  },
  {
    id: "mailId",
    label: "mailId",
  },
  {
    id: "client",
    label: "client",
  },
  {
    id: "assignmentId",
    label: "assignmentId",
  },
];

const columnsForExcel = [
  {
    id: "workflowId",
    displayName: "workflow ID",
  },
  {
    displayName: "workflowName",

    id: "workflowName",
  },
  {
    displayName: "workflowOverview",
    id: "workflowOverview",
  },

  {
    displayName: "Date",
    id: "date",
  },
  {
    displayName: "Time",
    id: "time",
  },
];

const colforAttach = [
  {
    id: "workflowDocId",
    label: "WorkflowDocId",
  },
  {
    id: "workflowName",
    label: "Workflow",
  },
  {
    id: "attachment",
    label: "Attachment Link",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "date ",
    label: "Date",
  },
  {
    id: "by ",
    label: "By",
  },
];
const colforUrls = [
  {
    id: "workflowDocId",
    label: "WorkflowDocId",
  },
  {
    id: "workflowName",
    label: "Workflow",
  },
  {
    id: "url",
    label: "Url Link",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "date ",
    label: "Date",
  },
  {
    id: "by ",
    label: "By",
  },
];
const colforChecklists = [
  {
    id: "workflowDocId",
    label: "WorkflowDocId",
  },
  {
    id: "workflowName",
    label: "Workflow",
  },
  {
    id: "checklists",
    label: "checklists",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "date ",
    label: "Date",
  },
  {
    id: "by ",
    label: "By",
  },
];
const colforComments = [
  {
    id: "workflowDocId",
    label: "WorkflowDocId",
  },
  {
    id: "workflowName",
    label: "Workflow",
  },
  {
    id: "comment",
    label: "comment",
  },

  {
    id: "date ",
    label: "Date",
  },
  {
    id: "by ",
    label: "By",
  },
];
const colforDoc = [
  {
    id: "workflowDocId",
    label: "WorkflowDocId",
  },
  {
    id: "workflowName",
    label: "Workflow",
  },
  {
    id: "attachment",
    label: "Attachment Link",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "date ",
    label: "Date",
  },
  {
    id: "by ",
    label: "By",
  },
];
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class pageWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      sliderState: 1,
      excelData: [],
      workFlowList: [],
      selectedClient: {},
      tags: [],
      currentRow: 0,
      superSliderState: "additional",
      mail: {
        contactPersons: [],
      },
      entry: {
        workFlowList: [],
      },
      isEdit: false,
      assignments: [],
      clients: [],
      contactPersonsClients: [],
      contactPersons: [],
      employees: [],
      columns: [
        {
          id: "workflowId",
          displayName: "workflow ID",
        },
        {
          displayName: "workflowName",

          id: "workflowName",
        },
        {
          displayName: "workflowOverview",
          id: "workflowOverview",
        },

        {
          displayName: "Date",
          id: "date",
        },
        {
          displayName: "Time",
          id: "time",
        },
      ],
    };
    context = this;
  }

  handleOnChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    this.setState({
      entry: { ...this.state.entry, [name]: value },
    });
  };

  handleOnChangeCol = (e) => {
    var name = e.target.name;
    var value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.fetchClients(this, id);
    this.fetchEmployees(this);

    this.fetchAssignments(this);
    // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }

  handleChange = (newValue, actionMeta) => {
    debugger;
    this.setState({ tags: newValue });

    setTimeout(() => {
      context.sendMail(context);
    }, 100);
  };

  generateExcelData = (data) => {
    debugger;
    let excelData = [];
    let attachments = [];
    let comments = [];
    let urls = [];
    let checklists = [];
    let documents = [];

    let row = {
      workflowId: data.workflowId,
      workflowName: data.workflowName,
      workflowOverview: data.workflowOverview,
      by: data.by,
      date: new Date(data.date).toDateString(),
      time: new Date(data.date).toLocaleTimeString(),
    };

    excelData.push(row);

    this.setState({ excelData });

    let workFlowList = data.workFlowList || [];

    workFlowList = _.map(workFlowList, function (w) {
      w.workflow = _.map(w.workflow, function (ww) {
        debugger;

        /* ww.attachment = context.getCommaSeperated(ww.attachment,'attachment');
                      ww.comment = context.getCommaSeperated(ww.comment,'comment');
                      ww.url = context.getCommaSeperated(ww.url,'url');
                      ww.checklist = context.getCommaSeperated(ww.checklist,'name');
                       */

        $.merge(
          attachments,
          context.getResourceWithName(ww.attachment, w.name) || []
        );
        $.merge(urls, context.getResourceWithName(ww.url, w.name) || []);
        $.merge(
          checklists,
          context.getResourceWithName(ww.checklist, w.name) || []
        );
        $.merge(
          comments,
          context.getResourceWithName(ww.comment, w.name) || []
        );
        $.merge(documents, context.getResourceWithName(ww.doc, w.name) || []);
        ww = _.omit(
          ww,
          "isEdit",
          "action",
          "index",
          "attachment",
          "comment",
          "url",
          "checklist",
          "doc"
        );
        return ww;
      });

      w.workflowColumns = _.filter(w.workflowColumns, function (ww) {
        return (ww.name != "" || !ww.name) && ww.name != "action";
      });

      return w;
    });

    this.setState({
      workFlowList,
      attachments,
      urls,
      comments,
      checklists,
      documents,
    });
  };

  getCommaSeperated = (list, props) => {
    let names = _.map(list, function (d) {
      return d[props];
    });

    return names.join(";");
  };

  getResourceWithName = (list, props) => {
    let names = _.map(list, function (d) {
      d.workflowName = props;
      return d;
    });

    return names;
  };

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl1 + "businus/getWorkflowById/" + id)
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });

        debugger;
        if (response.data.tags) {
          that.setState({ tags: response.data.tags });
        }

        //that.setState({sharedTo:response.data.sharedAssignment});
        that.generateExcelData(response.data);
        //that.generateColumnData(response.data);

        that.setState({
          selectedClient: response.data.selectedClient,
        });

        let sharedTo = response.data.sharedTo;

        that.setState({
          sharedTo: _.where(sharedTo, { type: "EMP" }),
        });

        let sharedToClient = _.filter(response.data.sharedTo, function (d) {
          return d.type != "EMP";
        });

        var selectedClient = _.findWhere(that.state.clients, {
          id: response.data.selectedClient,
        });
        let primaryContact = {
          id: selectedClient.id,
          name: selectedClient.primaryContact,
          email: selectedClient.customerEmail,
          label: selectedClient.primaryContact,
        };
        let contacts = selectedClient.contactPersons;

        let contactPersons = [];
        contactPersons.push(primaryContact);

        _.each(contacts, function (d) {
          contactPersons.push({ label: d.name, type: "contact", ...d });
        });
        // state['contactPersons'] = contactPersons;
        //state['subjectUpdate'] = selectedTemplate.update;

        that.setState({
          contactPersonsClients: contactPersons,
          sharedTo: sharedToClient,
        });

        setTimeout(() => {
          that.setState({
            sharedTo: sharedToClient,
          });
        }, 100);
      })

      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
      axios
        .delete(apiBaseUrl1 + "businusquery/getOrganisationById/" + id)
        .then(function (response) {
          debugger;
          console.log(response.data);
          alert("Organisation has been deleted");
          // that.setState({ entry: response.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  toggleFullScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  };

  sendMail(index) {
    var entry = context.state.entry;

    let sharedTo = context.state.sharedTo;
    sharedTo = _.union(sharedTo, context.state.sharedToClient);

    axios
      .post(apiBaseUrl1 + "businus/workflow/update/" + entry.id, {
        workFlowList: entry.workFlowList,
        workflowName: entry.workflowName,
        workflowOverview: entry.workflowOverview,
        sharedTo: sharedTo,
        selectedClient: context.state.selectedClient,
        tags: context.state.tags,
      })
      .then(function (response) {
        debugger;
        ToastsStore.success("Workflow Updated");
        context.fetchThreadById(context, entry.id);

        if (index != "COLUMN") context.toggleEdit(index);
      })
      .catch(function (error) {
        //toastr.error(error);
        console.log(error);
      });
  }
  saveToWorkflowList = (workflow, index) => {
    let entry = this.state.entry;

    entry.workFlowList[index] = workflow;

    this.setState({ entry: entry });
    ToastsStore.success("Workflow Saved to Workflow List");
    this.sendMail();
  };

  fetchAssignments(that, source) {
    axios
      .get(
        apiBaseUrl1 +
          "businus/project/list/" +
          sessionStorage.getItem("employeeId")
      )
      .then(function (response) {
        console.log(response.data);
        let assignments = [];
        _.each(response.data, function (d) {
          assignments.push({
            value: d.id,
            label: d.assignmentId,
            name: d.assignmentName,
            id: d.id,
          });
        });

        that.setState({ assignments: _.sortBy(assignments, "label") });

        //that.generateExcelData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSelect = (selectedOption) => {
    this.setState({ sharedTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );

    let entry = this.state.entry;

    entry.sharedTo = selectedOption;

    this.setState({ entry: entry });
    ToastsStore.success("Workflow Shared ");
    this.sendMail();
  };

  onRemove(optionList, removedItem) {
    context.setState({ sharedTo: optionList });
    let entry = this.state.entry;

    entry.sharedTo = optionList;

    this.setState({ entry: entry });
    ToastsStore.success("Workflow Shared ");
    this.sendMail();
  }

  deleteToWorkflowList = (index) => {
    var entry = this.state.entry;

    entry.workFlowList.splice(index, 1);

    entry.workFlowList = JSON.parse(JSON.stringify(entry.workFlowList));
    this.setState({ entry: entry });
    this.sendMail();
    ToastsStore.success("Deleted");
  };

  openWorkflowModal = () => {
    this.setState({ modalWorkflowIsOpen: true });
  };

  toggleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeWorkflowModal() {
    context.setState({ modalWorkflowIsOpen: false });
  }

  handleOnClientChange = (e) => {
    debugger;
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients, {
      id: e.target.value,
    });
    let primaryContact = {
      id: selectedClient.id,
      name: selectedClient.primaryContact,
      email: selectedClient.customerEmail,
      label: selectedClient.primaryContact,
    };
    let contacts = selectedClient.contactPersons;

    let contactPersons = [];
    contactPersons.push(primaryContact);

    _.each(contacts, function (d) {
      contactPersons.push({ label: d.name, type: "contact", ...d });
    });
    // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;

    state["contactPersonsClients"] = contactPersons;

    state["selectedClient"] = e.target.value;
    this.setState(state);

    this.sendMail(this);
    console.log(this.state);
  };
  fetchClients(that, id) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list")
      .then(function (response) {
        console.log(response.data);
        that.setState({ clients: _.sortBy(response.data, "customerId") });
        setTimeout(() => {
          that.fetchQueryById(that, id);
        }, 100);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  onSelectClients = (selectedOption) => {
    this.setState({ sharedToClient: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );

    this.sendMail(this);
  };

  onRemoveClients(optionList, removedItem) {
    context.setState({ sharedToClient: optionList });
    this.sendMail(this);
  }

  fetchEmployees(that, source) {
    axios
      .get(apiBaseUrl2 + "businus/employee/list")
      .then(function (response) {
        console.log(response.data);

        let allEmp = response.data;
        if (sessionStorage.userRole != "Admin") {
          allEmp = _.filter(allEmp, function (emp) {
            return emp.role != "Admin";
          });
        }

        let contactPersons = [];

        _.each(allEmp, function (member) {
          contactPersons.push({
            value: member.id,
            label:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            name:
              member.employeeId +
              " " +
              member.firstName +
              " " +
              member.lastName,
            id: member.id,
            email: member.email,
          });
        });

        that.setState({ employees: contactPersons });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSelect = (selectedOption) => {
    this.setState({ sharedTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );

    this.sendMail(this);
  };

  onRemove(optionList, removedItem) {
    context.setState({ sharedTo: optionList });
    this.sendMail(this);
  }

  addWorkflowInList = () => {
    let workflowName = this.state.workflowName;

    let newWorkflow = {
      name: workflowName,
    };

    let entry = this.state.entry;
    entry.workFlowList.push(newWorkflow);

    this.setState({ entry: entry });
    this.setState({ modalWorkflowIsOpen: false });
    this.sendMail();
  };

  //updating the status button

  render() {
    const active = " active-button";
    const deactive = "";

    return (
      <div className="Div">
        <div className="row breadcrumb1-row" style={{ marginLeft: "2px" }}>
          <div className="col-md-10">
            <BreadCrumbs />
          </div>
          <div className="col-md-2">
            <ExcelFile
              element={
                <button className="download-button-wrapper">
                  Download Data in Excel format
                </button>
              }
            >
              <ExcelSheet data={this.state.excelData} name="WorkflowList">
                {this.state.columns != undefined
                  ? this.state.columns.map((d) => (
                      <ExcelColumn label={d.displayName} value={d.id} />
                    ))
                  : ""}
              </ExcelSheet>

              {this.state.workFlowList.map((d) => (
                <ExcelSheet data={d.workflow} name={d.name}>
                  {d.workflowColumns != undefined
                    ? d.workflowColumns.map((dd) => (
                        <ExcelColumn label={dd.name} value={dd.id} />
                      ))
                    : ""}
                </ExcelSheet>
              ))}
              <ExcelSheet data={this.state.attachments} name="Attachments">
                {colforAttach.map((d) => (
                  <ExcelColumn label={d.label} value={d.id} />
                ))}
              </ExcelSheet>
              <ExcelSheet data={this.state.documents} name="Documents">
                {colforDoc.map((d) => (
                  <ExcelColumn label={d.label} value={d.id} />
                ))}
              </ExcelSheet>
              <ExcelSheet data={this.state.checklists} name="Checklists">
                {colforChecklists.map((d) => (
                  <ExcelColumn label={d.label} value={d.id} />
                ))}
              </ExcelSheet>
              <ExcelSheet data={this.state.urls} name="Urls">
                {colforUrls.map((d) => (
                  <ExcelColumn label={d.label} value={d.id} />
                ))}
              </ExcelSheet>
              <ExcelSheet data={this.state.comments} name="Comments">
                {colforComments.map((d) => (
                  <ExcelColumn label={d.label} value={d.id} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
        <div className="row">
          <div className="dedicated-page" style={{ padding: "0px" }}>
            <div className="row-resource-head">
              <h2 className="resource-head">{this.state.entry.workflowId} </h2>
            </div>
            <div className="">
              <div style={{ height: "260px" }} className="row header-divi ">
                <div className="col-md-10">
                  <div className="form-group">
                    <label className="control-label col-md-3">
                      Workflow Id:
                    </label>
                    <div className="col-md-7">
                      <input
                        name="workflowId"
                        disabled="disabled"
                        value={this.state.entry.workflowId}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-3">
                      Workflow Name:
                    </label>
                    <div className="col-md-7">
                      <input
                        name="workflowName"
                        onChange={this.handleOnChange}
                        disabled={this.state.isEdit ? "" : "disabled"}
                        value={this.state.entry.workflowName}
                        onBlur={this.sendMail}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                  {/*  <div className="form-group">
                          <label className="control-label col-md-3">
                            Attached to assignment
                          </label>
                          <div className="col-md-7">

                         
                            {this.state.entry ?  <Select
                             onChange={this.onSelect}
                              value={this.state.sharedTo}
                             options={this.state.assignments}
                             isMulti={true}
                           /> : ''}
                           
                       {/*} <select
                            name="selectedAssignment"
                            onChange={this.handleOnAssignChange}
                            value={this.state.selectedAssignment}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Assignment</option>
                            {this.state.assignments.map(function (client, i) {

                              return (<option value={client.id}>{client.assignmentId} {client.assignmentTitle}</option>)
                            })}

                          </select>
                        </div>
                        </div>*/}
                  <div className="form-group">
                    <label className="control-label col-md-3">
                      Workflow Overview:
                    </label>
                    <div className="col-md-7">
                      <input
                        name="workflowOverview"
                        onChange={this.handleOnChange}
                        onBlur={this.sendMail}
                        disabled={this.state.isEdit ? "" : "disabled"}
                        value={this.state.entry.workflowOverview}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                  <span>
                    <div className="form-group">
                      <label className="control-label col-md-3">Client:</label>
                      <div className="col-md-7">
                        <select
                          name="selectedClient"
                          onChange={this.handleOnClientChange}
                          value={this.state.selectedClient}
                          className="form-control ember-text-field ember-view"
                          type="text"
                        >
                          <option value="">Select Client</option>
                          {this.state.clients.map(function (client, i) {
                            return (
                              <option value={client.id}>
                                {client.customerId} {client.companyName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label col-md-3">
                        Shared To (Client Contacts)
                      </label>
                      <div className="col-md-7">
                        {this.state.contactPersonsClients.length > 0 ? (
                          <Select
                            value={this.state.sharedToClient}
                            onChange={this.onSelectClients}
                            options={this.state.contactPersonsClients}
                            isMulti={true}
                          />
                        ) : (
                          <input
                            name="subject"
                            disabled="true"
                            value="Select Client Above"
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">
                        Shared To (Team):
                      </label>
                      <div className="col-md-7">
                        {this.state.employees.length > 0 ? (
                          <Select
                            value={this.state.sharedTo}
                            onChange={this.onSelect}
                            options={this.state.employees}
                            isMulti={true}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">Tags</label>
                      <div className="col-md-7">
                        <CreatableSelect
                          isMulti
                          onChange={this.handleChange}
                          options={this.state.tags}
                          value={this.state.tags}
                        />
                      </div>
                    </div>
                  </span>
                </div>
                {/*       <div className="col-md-2">
                  <button className="TrashButtonColumn btn-danger" style={{ marginTop: '60px' }} onClick={() => this.toggleEdit()}>
                    {this.state.isEdit == true ? <i className="fa fa-save" style={{ color: 'black' }}></i> : <i className="fa fa-edit"></i>}
                  </button>
                </div> */}
              </div>
            </div>

            <Modal
              isOpen={this.state.modalWorkflowIsOpen}
              onRequestClose={this.closeWorkflowModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right">
                <div
                  style={{ marginRight: "-5px" }}
                  onClick={this.closeWorkflowModal}
                >
                  <i
                    style={{ fontSize: "25px", fontWeight: "400" }}
                    className="fa fa-close"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="header2">Add Worklow name</div>

              <div className="row content1-div  text-left">
                <input
                  className="fieldbox1"
                  onChange={this.handleOnChangeCol}
                  type="text"
                  value={this.state.workflowName}
                  name="workflowName"
                  placeholder="Workflow Name"
                  style={{
                    fontSize: "17px",
                    fontFamily: "montserrat,sans-serif",
                  }}
                />

                <div className="text-center">
                  {" "}
                  <div
                    className="btn btn-primary send-button"
                    onClick={() => this.addWorkflowInList()}
                    placeholder="Attachment"
                  >
                    Submit
                  </div>
                </div>
              </div>
            </Modal>

            {/*     <div className="text-left">
              <button
                type="button"
                onClick={this.sendMail}
                className="button1"
              >
                {" "}
                      Submit{" "}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
