import React, { Component } from "react";
import axios from 'axios';

import { Link } from "react-router-dom";
import ReactTable from 'react-table'
import $ from "jquery";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import 'react-table/react-table.css'
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import _ from 'underscore';
import {Multiselect} from 'multiselect-react-dropdown';
import { apiBaseUrl1,apiBaseUrl5, apiIssueMaster } from '../apiUtils';
import { apiBaseUrl2, getHeader } from '../apiUtils';
import ResourcesUserCommunication from './ResourcesUserCommunication';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};

let context = null;

export default class AssignmentUserCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      message: "",
      priority:"",
      organisationThred:[],
      attachment: "NA",
      workflows:[],
      thread: [],
      senderEmail: "",
      documents: [],
      proposals: [],
      timelineForTeams:[],
      teamMails:[],
      teamDocs:[],
      mails: [],
      entry: {
        checklists:[],
        team:[]
      },
      assignmentDocuments:[],
      attachmentPlaceholders:[
        {attachment:''}
    ],
      assignmentComments:[],
      templatesForMail:[],
      templates:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      superSliderState: "clients"
    };

    context = this;
  }


  fetchTemplatesForMails(that,source){
    
    axios.get(apiBaseUrl1+"businus/template/list/client/" + sessionStorage.spId)
    .then(function (response) {
      console.log(response.data);
      var templates =_.where(response.data,{templateType:"Messages"});
       templates.unshift({name:"Custom ",update:"",id:"-1"});
      
      that.setState({templatesForMail:templates});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
fetchTemplates(that,source){

  axios.get(apiBaseUrl1+"businus/template/list/Update/" + sessionStorage.spId)
  .then(function (response) {
    console.log(response.data);
    var templates = response.data;
     templates.unshift({name:"Custom update",update:""});

    that.setState({templates});
  })
  .catch(function (error) {
    console.log(error);
  });

}


componentDidMount(){
  this.markAsRead(this,this.props.id);
 
  this.setState({entry:this.props.entry});
  this.setState({id:this.props.id});
  this.fetchTemplates(this);
  this.fetchWorkflows(this);
  this.fetchTemplatesForMails(this);
   this.fetchThreadById(this,this.props.id);
    
  
  
 
 // this.fetchTeamMails(this,"TEAM-MAIL");
  

}
  
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  handleOnTemplateChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedTemplate = _.findWhere(this.state.templates,{id:e.target.value});
    state['selectedUpdate'] = selectedTemplate.update;
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);


    console.log(this.state);
  }

  
  handleOnMailTemplateChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedTemplate = _.findWhere(this.state.templatesForMail,{id:e.target.value});
    state['subject'] = selectedTemplate.name;
    state['message'] = selectedTemplate.update;
    if(selectedTemplate.document!="")
    state['attachments'] = [{attachmentName:'template attachment' , attachment:selectedTemplate.document}];
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);


    console.log(this.state);
  }

  
  onCheckboxClick = (e,i)=>{
    var query = context.state.entry;


  
    query.checklists[i].isDone = !query.checklists[i].isDone;

    

    axios
    .post(
      apiBaseUrl1+"businus/project/update/"+context.state.id,
      {

       status:query.status!=undefined ? query.status : "open",
       paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
       checklists:query.checklists
      }, getHeader())
    .then(function (response) {
      
      context.setState({entry:query});
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true,attachmentPlaceholders:[{attachment:''}] });
  }


  saveResourceToList=(resources)=>{

    if(context.props.entry.selectedOrganisation.id==0){
       axios
       .put(apiIssueMaster+"businusquery/issue/"+context.state.id, 
         {
   
           resources:resources
         },getHeader())
       .then(function (response) {
         debugger;
         alert("success");
        // context.fetchThreadById(context,context.state.id);
       })
       .catch(function (error) {
         console.log(error);
       });
     

    }else{
      axios
      .post(
        apiBaseUrl1+"businus/project/update/"+context.state.id, 
        {
  
          resources:resources
        },getHeader())
      .then(function (response) {
        debugger;
        alert("success");
       // context.fetchThreadById(context,context.state.id);
      })
      .catch(function (error) {
        console.log(error);
      });
    
    }

 



  }


  updateStatus =  (type,value) => {
    var query = context.state.entry;

    if(type=='checklists'){

      if(query.checklists!=undefined){

        query.checklists.push({
          name:value,
          isDone:false
        })

      }
  else{
    query['checklists'] = [];
    query.checklists.push({
      name:value,
      isDone:false
    })
    }

    }


    axios
      .post(
        apiBaseUrl1+"businus/project/update/"+context.state.id, 
        {

         status:query.status!=undefined ? query.status : "open",
         paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
         checklists:query.checklists
        }, getHeader())
      .then(function (response) {
        debugger;
        alert("success");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }


  handleQuillChange=(value)=> {
    this.setState({ message: value })
    // document.getElementById('save').innerHTML = 'Add Act';
  }
  closeModal=()=> {
    this.setState({ modalIsOpen: false });
  }


  markAsRead(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/read/" +
        id,  getHeader()
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    
    let promises  = [];


    _.each(that.state.selectedFile,function(selectedFile){


        promises.push(axios.post(apiBaseUrl2+"businusquery/uploadAttachment",{"uploadImage": selectedFile.base64,"imageName": selectedFile.name}), getHeader());


    });


      Promise.all(promises).then(result => {

        let attachments = that.state.attachments || [];
       

        _.each(result,function(response,key){

          if (response.status == 200) {
            that.setState({ successupload: true });

            attachments.push({
              attachmentName:that.state.selectedFile[key].name,
              attachment:"https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile[key].name
            })
          } 
      


        });


        that.setState({attachments:attachments});



      }).catch(error => console.log(error));

    


  }

  fetchWorkflows(that, source) {


    axios.get(apiBaseUrl1+ "businus/workflow/list/" + sessionStorage.spId,  getHeader())
    .then(function (response) {
      console.log(response.data);
      var workflows = response.data;
        
      workflows.unshift({name:'custom',description:''});
      workflows.unshift({name:'Select a workflow',description:''});
      that.setState({ workflows: response.data });
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }


  fetchThreadById(that, id) {

  
debugger;
    let url = (apiBaseUrl2+"businusquery/listmail/" + id);
    axios
      .get(
        url,
        getHeader() 

      )
      .then(function (response) {
        debugger;
        console.log(response.data);

        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
      

        if(context.props.isDedicatedPage){
          threads = _.where(threads,{parentMailId:context.state.entry.id});
        }
        else{
          threads = _.filter(threads,function(d){
            return d.isThreadMail!="Y";
          });
        }


        var documents = _.where(threads, { docType: "DOCUMENT" });
        var proposals = _.where(threads, { docType: "PROPOSAL" });
        var mails = _.where(threads, { docType: "Mail" });
        var updates = _.where(threads, { docType: "Update" });
       
        var assignmentDocuments = _.where(threads, { docType: "assignmentDocument" });
        var progressLogs = _.where(threads, { docType: "progressLog" });
        var expenses = _.where(threads, { docType: "expenseLog" });
        var issues = _.where(threads, { docType: "issueLog" });
        var assignmentComments = _.where(threads, { docType: "assignmentComment" });
        var teamMails = _.where(threads,{docType:"TEAM-MAILS"});
        var teamDocs = _.where(threads,{docType:"TEAM-DOCS"});

        var timeline = _.filter(threads,function(t){
          
          return t.docType!="TEAM-MAILS" && t.docType!="TEAM-DOCS";
        
        });
        that.setState({ thread: timeline });


        var timelineForTeams = _.filter(threads,function(t){
          
          return t.docType=="TEAM-MAILS" || t.docType=="TEAM-DOCS";
        
        });
        that.setState({ timelineForTeams });


        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
        that.setState({ updates });
        that.setState({ teamMails,teamDocs });
        
        that.setState({assignmentComments,progressLogs,expenses,issues,assignmentDocuments});
        that.setState({organisationThread:_.union(assignmentComments,progressLogs,expenses,issues,assignmentDocuments)});


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }


  

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='clients'){
      this.setState({ sliderState: 1 });
    }
    else if(name=='resources'){
      this.setState({ sliderState: 21 });
    }
    else if(name=="workflow"){
      this.setState({ sliderState: 15 });
    }
    else {
      this.setState({ sliderState: 13 });
    }
  

  }


  getFiles(files) {
    this.setState({ selectedFile: files });
    this.fileUploadHandler(this);

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }
  getFormattedTime(millis) {

    return new Date(millis).toLocaleTimeString();


  }

  
  changeDocType(type) {

    this.setState({ docType: type });
  }

  onSelect(optionsList, selectedItem) {

    context.setState({team:optionsList});

    console.log(optionsList,selectedItem);
}

onRemove(optionList, removedItem) {
  context.setState({team:optionList});
}



addNewWorkflow = () =>{

  debugger;
  let selectedWorkflow = this.state.workflows[this.state.selectedWorkflow];



  this.props.addNewWorkflow(selectedWorkflow);


}


addMoreAttachment = () =>{
  let attachmentPlaceholders = this.state.attachmentPlaceholders;
  attachmentPlaceholders.push({attachment:''});
  this.setState({attachmentPlaceholders});

}


sendMail(type) {
  var query = context.props.entry;
   axios
    .post(
     apiBaseUrl2+"businusquery/send",
      {

        "name": query.name,
        "email": query.email,
        "message":context.state.message, //type=='update' ? context.state.selectedUpdate: 
        "company": query.company,
        "toe": query.toe,
        "subject": type=='update' ? context.state.subjectUpdate : context.state.subject!="" ? context.state.subject : 'NA',
        "priority": context.state.priority!="" ? context.state.priority : "NA",
        "attachment": context.state.attachments,
        "queryId": context.props.id,
        "from": "admin",
        "recordType": "MAIL",
        "parentMailId":query.id,
        "docType": context.state.docType,
        "by":sessionStorage.userName,
        "amount":context.state.amount,
        isThreadMail:context.props.isDedicatedPage ? "Y" : 'N',
        "team":context.state.team,
        screenType: context.props.type,
        "orgId":context.props.isDedicatedPage ? context.props.assignment.selectedOrganisation.id : context.props.entry.selectedOrganisation.id,
        "visibleToClient": context.props.isDedicatedPage ?  context.props.assignment.visibleToClient :context.props.entry.visibleToClient
      }, getHeader())
    .then(function (response) {
      debugger;
      alert("SENT");
   
        context.fetchThreadById(context, context.props.id);
      
      context.setState({subject:""});
      context.setState({message:""});
    })
    .catch(function (error) {
      console.log(error);
    });
}

fetchTeamMails(that,mailType){

  axios.get(apiBaseUrl2+"businusquery/listMailByType/all/"+mailType,  getHeader())
  .then(function (response) {
    console.log(response.data);
    that.setState({teamMails:_.sortBy(response.data,"date")});
  })
  .catch(function (error) {
    console.log(error);
  });

}

sendMailForEmployee(that,mailType) {
  var query = context.props.entry;
 
  axios
    .post(
     apiBaseUrl2+"businusquery/sendByMaster",
      {"name": query.name,
      "email": query.email,
      "message":context.state.message, //type=='update' ? context.state.selectedUpdate: 
        "company": query.company,
        "subject":  context.state.subject ,
        "priority": context.state.priority!=="" ? context.state.priority : "NA",
        "attachment": context.state.attachments,
        "queryId": context.props.id,
        "parentMailId":query.id,
        "from": "admin",
        "recordType": "EMP-MAIL",
        "docType": mailType,
        "senderEmail": query.team
      },  getHeader())
    .then(function (response) {
      debugger;
      alert("SENT");

   
        context.fetchThreadById(context, context.props.id);
      
     
     
      context.setState({subject:""});
      context.setState({message:""});
    })
    .catch(function (error) {
      console.log(error);
    });
}

setMailPageUrl =() => {
debugger
  if(!this.props.isDedicatedPage){
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }
 


}


  render() {
const {sliderState,superSliderState, entry} = this.state;   
const resources = this.props.entry.resources || {
  attachment:[],
  doc:[],
  comment:[],
  url:[]
}; 
    return( 
      <div>



    
     <div className="row " style={{backgroundColor:'white', borderBottom:'2px solid grey'}}>
     <div className={superSliderState == 'clients' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('clients')} >Clients</div>
     </div>

    {/*<div className={superSliderState == 'organisation' ? "col-md-2 active2 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('organisation')}>Teams</div>
    </div>*/}

    <div className={superSliderState == 'resources' ? "col-md-2 active3 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('resources')}>Resources</div>
    </div>

    <div className={superSliderState == 'workflow' ? "col-md-2 active4 superoptions" : "col-md-2 superoptions"} >
       <div className="" onClick={() => this.changeSuperSliderState('workflow')}>Workflow</div>
    </div>
  
    

    </div>


{superSliderState==='clients' ? 

   <div className="row " style={{borderTop:"2px solid #1E5B81"}} >
     <div className={sliderState == 1 ? "col-md-3 activeOptionsAss " : "col-md-3"} style={{ backgroundColor: '#115e95' }}>
       <div className="options " onClick={() => this.changeSliderState(1)} > Timeline</div>
     </div>
     <div className={sliderState == 3 ? "col-md-2 activeOptionsAss" : "col-md-2"} style={{ backgroundColor: '#2c6ca3' }}>
       <div className="options" onClick={() => this.changeSliderState(3)}>Message</div>
     </div>
     <div className={sliderState == 4 ? "col-md-3 activeOptionsAss " : "col-md-3"} style={{ backgroundColor: '#3f7baf' }}>
       <div className="options" onClick={() => this.changeSliderState(4)}> Proposals</div>
     </div>
     <div className={sliderState == 5 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: '#63a5df' }}>
       <div className="options" onClick={() => this.changeSliderState(5)}> Documents</div>
     </div>
     <div className={sliderState == 6 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: '#82c0f7' }}>
       <div className="options" onClick={() => this.changeSliderState(6)}> Updates</div>
     </div>
   </div>

   :
   superSliderState==='resources' ? 

        <ResourcesUserCommunication 
        attachment={resources.attachment}
        doc={resources.doc}
        comment={resources.comment}
        checklist={resources.checklist || []}
        url={resources.url}
        saveResourceToList={this.saveResourceToList}
        parentType="Assignment"
        parentId={this.props.entry.id}
        assignment={this.props.entry}
        parentName={this.props.entry.assignmentId}
        />

        : superSliderState==='workflow' ? 


        <div >  <div className="row " >
     <div className={sliderState == 15 ? "col-md-6 activeOptionsAss " : "col-md-6"} style={{ backgroundColor: 'rgb(54 186 135)' }}>
       <div className="options " onClick={() => this.changeSliderState(15)} > Timeline</div>
     </div>
    
     <div className={sliderState == 16 ? "col-md-6 activeOptionsAss " : "col-md-6"} style={{ backgroundColor: 'rgb(56 202 158)' }}>
       <div className="options" onClick={() => this.changeSliderState(16)}>Add a Workflow</div>
     </div>
   
    
   </div> { sliderState == 15 ? <div className="row">
        <div className="col-md-12 offset-md-3">
        
 
          <ul className="timeline" style={{backgroundColor:'white'}}>
 
            {this.props.entry.workflow ? this.props.entry.workflow.map((thred, index) => (
 
              <li>
                <div className={(thred.docType == 'Proposal' ? 'RED' : thred.docType == 'Document' ? 'GREEN' :thred.docType == 'Mail' ? 'YELLOW': 'BLUE') + '-mail timeline-card'}>
                  <div className="row ">
                  <div className="col-md-8">  <h4 className="montserrat timeline-head" > <Link to={"/workflow/view/" + thred.id} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.workflowId}</Link></h4></div>
            <div className="col-md-4 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.workflowName}</p></button></div></div>
                <div className="row"> 
               
                  </div>
                
                </div>
              </li>
            )) : ''}
 
 
          </ul>
        </div>
      </div> : 
      
        <div className="row">
           <div className="form-group">
                        <label className="control-label col-md-3">Add New Workflow</label>
                        <div className="col-md-7">
                          <select
                            name="selectedWorkflow"
                            onChange={this.onChange}
                            value={this.state.selectedWorkflow}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.workflows.map(function (template, i) {

                              return (<option value={i}>{template.workflowId} {template.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <button type="button" onClick={this.addNewWorkflow} className="button1">
                    Add Workflow
                    </button>
          </div>}
      
      </div>
   :

   <div className="row " >
     <div className={sliderState == 13 ? "col-md-4 activeOptionsAss " : "col-md-4"} style={{ backgroundColor: 'rgb(54 186 135)' }}>
       <div className="options " onClick={() => this.changeSliderState(13)} > Timeline</div>
     </div>
    
     <div className={sliderState == 8 ? "col-md-4 activeOptionsAss " : "col-md-4"} style={{ backgroundColor: 'rgb(56 202 158)' }}>
       <div className="options" onClick={() => this.changeSliderState(8)}>Messages</div>
     </div>
     <div className={sliderState == 9 ? "col-md-4 activeOptionsAss " : "col-md-4"} style={{ backgroundColor: 'rgb(131 219 185)' }}>
       <div className="options" onClick={() => this.changeSliderState(9)}>Documents</div>
     </div>
    
   </div>
}


   {sliderState == 1 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">
         <div className=" header" >Your Timeline</div>

         <ul className="timeline" style={{backgroundColor:'white'}}>

           {this.state.thread.map((thred, index) => (

             <li>
               <div className={(thred.docType == 'Proposal' ? 'RED' : thred.docType == 'Document' ? 'GREEN' :thred.docType == 'Mail' ? 'YELLOW': 'BLUE') + '-mail timeline-card'}>
                 <div className="row ">
                 <div className="col-md-8">  <h4 className="montserrat timeline-head" > <Link onClick={this.setMailPageUrl} to={"/assignment-mail/" + thred.id} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4></div>
                   <div className="col-md-4 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.docType=="Mail" ? "Message" : thred.docType}</p></button></div></div>
               <div className="row"> 
              
               <div className="col-md-8">{(thred.from=='client'? thred.status :'SENT')}</div>
               <div className="col-md-4">  <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4></div>
                 </div>
               
               </div>
             </li>
           ))}


         </ul>
       </div>
     </div></div> : ''}


   {sliderState == 2 ?
     <div>


       <div className="row">
         <div className="col-md-12 offset-md-3">
           <div className=" header" >Chat with our Assistants</div>
           {/*       <ul className="proposals">
  
 {this.state.thread.map((thred, index) => (
   
  <li>
    <div className="update-card">
      {thred.status}
 <h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
 <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
  <p className="montserrat timeline-subhead">{thred.id}</p>
  </div>
</li>
))}
         
        
 </ul>*/}
           <div style={{ backgroundColor: '#fffdf2' }}>
             <ChatFeed
               messages={this.state.messages} // Boolean: list of message objects
               isTyping={this.state.is_typing} // Boolean: is the recipient typing
               hasInputField={false} // Boolean: use our input, or use your own
               showSenderName // show the name of the user who sent the message
               bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
               // JSON: Custom bubble styles
               bubbleStyles={
                 {
                   text: {
                     fontSize: 19
                   },
                   chatbubble: {
                     borderRadius: 20,
                     padding: 10,
                     backgroundColor: '#0084FF',

                   },
                   recipientChatbubble: {
                     backgroundColor: '#f29624',
                   }
                 }
               }
             />

           </div>
           <div className="row message-box text-left">
             {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> */}
             <textarea className="fieldbox1" onChange={this.onChange} type="text" value={this.state.messageText} name="messageText" placeholder="Message" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }}></textarea><br />
             <div className="text-center"> <button className="btn btn-primary send-big-btn" onClick={this.sendMessage} placeholder="Attachment">Send</button></div>



           </div>
         </div>
       </div>
     </div> : ''}

   {sliderState == 3 ?
     <div>  <div className=" header" >Message</div>
       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('Mail')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Send Message</div></div>

       <Modal
         isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">Send Message</div>
         <div className="row" >
           <div className="options-row ">

           <div className="row ">
<select
               name="selectedTemplate"
               onChange={this.handleOnMailTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templatesForMail.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>


</div>

{this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>





           <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Message" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'240px'}}/>
           <div className="text-center"> <div className="btn btn-primary send-button" onClick={this.sendMail} placeholder="Attachment">Send</div></div>



          </div>

       </Modal>
       <div className="row">
         <div className="col-md-12 offset-md-3">
          
           <tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Subject
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.mails!=undefined ? this.state.mails.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           )):''}
         </ul>


       </tr>

          
         </div>
       </div>
     </div> : ''}


   {sliderState == 4 ?
     <div>
       <div className="header">
         Proposals
          </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Proposal')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Send Proposal</div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Send Proposal
</div>

         <div className="row" >
           <div className="options-row ">
           <select
               name="selectedTemplate"
               onChange={this.handleOnMailTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templatesForMail.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>
             {this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>





         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Proposal" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
           <div className="text-center"> <div className="btn btn-primary send-button" onClick={this.sendMail} placeholder="Attachment">Send</div></div>



         </div>

       </Modal>


       <tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Proposal Name
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.proposals != undefined ? this.state.proposals.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                     {thred.by}
        </td>
                   
                 </div>
               </li> 
           )):''}
         </ul>


       </tr></div> : ''}
   {sliderState == 5 ?
     <div>
       <div className="header">
         Documents
       </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Document')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Send Document</div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Send Document
</div>

         <div className="row" >
           <div className="options-row ">
           <div className="row ">
<select
               name="selectedTemplate"
               onChange={this.handleOnMailTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templatesForMail.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>


</div>
{this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>






         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Document" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
           <div className="text-center"> <div className="btn btn-primary send-button" onClick={this.sendMail} placeholder="Attachment">Send</div></div>



         </div>

       </Modal>






       <tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Document Name
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.documents != undefined ? this.state.documents.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           )):''}
         </ul>


       </tr></div> : ''}







    {sliderState == 6 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">

         <div className=" header" >Updates</div>

         <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Update')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Send Update</div>*/}</div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
Send Update
</div>

<div className="row" >
<div className="options-row ">
<div className="col-md-6 upload-box" > <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)} /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
 value="Select a File">
 <i className="fa fa-paperclip" aria-hidden="true"></i>Attach your Document
</button></div>
</div>
</div>





<div className="row content1-div  text-left">
<select
               name="selectedTemplate"
               onChange={this.handleOnTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templates.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>
<input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subjectUpdate} name="subjectUpdate" placeholder="Subject of your Update" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
<ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
<div className="text-center"> <div className="btn btn-primary send-button" onClick={()=>this.sendMail('update')} placeholder="Attachment">Send</div></div>



</div>

</Modal>
<tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Update
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.updates != undefined ? this.state.updates.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           )):''}
         </ul>


       </tr>
       </div>
     </div></div> : ''}


     {/* Checklists */}


     {sliderState == 7 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">
         <div className=" header" >Checklists </div>
         <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Document')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add Item</div>*/}</div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
Add Checklist Item
</div>

<div className="row content1-div  text-left">

<input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.title} name="title" placeholder="title" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />

<div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.props.addNewChecklist('checklists',this.state.title)} placeholder="Attachment">Add</button></div>



</div>

</Modal>
         <ul style={{padding:'20px'}} className="checkList">

           {this.props.entry.checklists!=undefined ? this.props.entry.checklists.map((check, index) => (

             <li className="checkList-item">
               <label>
               <input type="checkbox" defaultChecked={check.isDone} value={check.isDone}  onChange={(e)=>this.props.onCheckboxClick(e,index)}/><span className="check-title">{check.name}</span></label>
             </li>
           )) : ''}


         </ul>
       </div>
     </div></div> : ''}


  {/* Checklists */} 


   
       


       {sliderState == 19 ?
     <div>
       <div className="header">
         Progress Log
       </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('progressLog')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add Progress</div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Add Progress
</div>

         <div className="row" >
           <div className="options-row ">
           {this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>






         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Title" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
           <div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.sendMail('progressLogs')} placeholder="Attachment">Add</button></div>



         </div>

       </Modal>






       <div className="doc-section">
         <div className="row doc-head-row1">
           <div className="col-md-6">
             <div className="table-head">
               Log Subject
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               Uploaded On
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               By
     </div>
           </div>
         </div>

         <ul className="progressLogs">

           {this.state.progressLogs!=undefined ? this.state.progressLogs.map((thred, index) => (
             thred.attachment != "NA" ?
               <li>
                 <div className="row ">
                   <div className="col-md-6">
                     <div className="table-item ">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </div>
                   </div>
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </div>
                   </div>
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       {thred.from}
                     </div>
                   </div>
                 </div>
               </li> : ''
           )):''}
         </ul>




       </div></div> : ''}


       {sliderState == 10 ?
     <div>
       <div className="header">
         Expense Log
       </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('expenseLog')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add Expense</div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Add Expense
</div>

         <div className="row" >
           <div className="options-row ">
           {this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>







         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="TITLE" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
           <div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.sendMail('expenseLog')} placeholder="Attachment">Add</button></div>



         </div>

       </Modal>






       <div className="doc-section">
         <div className="row doc-head-row1">
           <div className="col-md-3">
             <div className="table-head">
               Amount
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               For
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               Uploaded On
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               By
     </div>
           </div>
         </div>

         <ul className="progressLogs">

{this.state.expenses!=undefined ? this.state.expenses.map((thred, index) => (
thred.attachment != "NA" ?
<li>
<div className="row ">
<div className="col-md-3">
 <div className="table-item ">
   <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
 </div>
</div>
<div className="col-md-3">
 <div className="table-item text-center">
   {thred.message}
 </div>
</div>
<div className="col-md-3">
 <div className="table-item text-center">
   {this.getFormattedDate(thred.date)}
 </div>
</div>
<div className="col-md-3">
 <div className="table-item text-center">
   {thred.from}
 </div>
</div>
</div>
</li> : ''
)):''}
</ul>




       </div></div> : ''}


       {sliderState == 11 ?
     <div>
       <div className="header">
         Issue Log
       </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('issueLog')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Raise Issue</div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Issue
</div>

         <div className="row" >
           <div className="options-row ">
           {this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>






         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="ssssue" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
<select name="senderEmail" value={this.state.senderEmail} onChange={this.onChange} ><option>High</option>
<option>Medium</option>
<option>Low</option></select>
           <div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.sendMail('issueLog')} placeholder="Attachment">Add</button></div>



         </div>

       </Modal>






       <div className="doc-section">
         <div className="row doc-head-row">
           <div className="col-md-3">
             <div className="table-head">
               Issue
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               Priority
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               Uploaded On
     </div>
           </div>
           <div className="col-md-3">
             <div className="table-head">
               By
     </div>
           </div>
         </div>

         <ul className="issueLogs">

           {this.state.issues!=undefined ? this.state.issues.map((thred, index) => (
             thred.attachment != "NA" ?
               <li>
                 <div className="row doc-head-row">
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </div>
                   </div>
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       {thred.senderEmail}
                     </div>
                   </div>
                   <div className="col-md-3">
 <div className="table-item text-center">
   {this.getFormattedDate(thred.date)}
 </div>
</div>
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       {thred.from}
                     </div>
                   </div>
                 </div>
               </li> : ''
           )):''}
         </ul>




       </div></div> : ''}











       {sliderState == 12 ?
     <div>
       <div className="header">
        comments
       </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('assignmentComment')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add Comments</div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           comments
</div>

     





         <div className="row content1-div  text-left">

         
           <textarea className="fieldbox1" onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Any comments" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }}></textarea><br />

           <div className="text-center"> <button className="btn btn-primary send-button" onClick={()=>this.sendMail('assignmentComment')} placeholder="Attachment">Add</button></div>



         </div>

       </Modal>






       <div className="doc-section">
         <div className="row doc-head-row">
           <div className="col-md-6">
             <div className="table-head">
               comments
     </div>
           </div>
       
         </div>

         <ul className="proposals">

           {this.state.assignmentComments!=undefined ? this.state.assignmentComments.map((thred, index) => (
             thred.attachment != "NA" ?
               <li>
                 <div className="row doc-head-row">
                   <div className="col-md-6">
                     <div className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.message}</Link>
                     </div>
                   </div>
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </div>
                   </div>
                   <div className="col-md-3">
                     <div className="table-item text-center">
                       {thred.from}
                     </div>
                   </div>
                 </div>
               </li> : ''
           )):''}
         </ul>




       </div></div> : ''}








       {sliderState == 13 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">
         <div className=" header" >Your Timeline</div>

         <ul className="timeline">

           {this.state.timelineForTeams.map((thred, index) => (

             <li>
               <div className={(thred.docType == 'Proposal' ? 'RED' : thred.docType == 'Document' ? 'GREEN' : 'BLUE') + '-mail timeline-card'}>
                 <div className="row ">
                   <div className="col-md-8">{thred.status}</div>
                   <div className="col-md-4 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.docType}</p></button></div></div>
                 <h4 className="montserrat timeline-head" > <Link onClick={this.setMailPageUrl} to={"/assignment-mail/" + thred.id} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
                 <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4>
                 <p className="montserrat timeline-subhead">{thred.id}</p>
                 <p className="montserrat timeline-subhead">{thred.docType}</p>
               </div>
             </li>
           ))}


         </ul>
       </div>
     </div></div> : ''}














     {sliderState == 14 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">

         <div className=" header" >Updates</div>

         <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Update')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Send Update</div>*/}</div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
Send Update
</div>

<div className="row" >
<div className="options-row ">
{this.state.attachmentPlaceholders.map((attach,i)=>{

return <div style={{marginTop:'5px'}} className="row">
<div className="col-md-8 upload-box" > <FileBase64
  multiple={true}
  onDone={this.getFiles.bind(this)} /></div>


{i===0 ? 
<div className="col-md-3">

  <button className="btn btn-primary attachment" onClick={() => this.addMoreAttachment()}
    value="Select a File">
    <i className="fa fa-plus" aria-hidden="true"></i>Add More
</button>   



</div> : ''}
</div>

})}
           
           </div>
         </div>







<div className="row content1-div  text-left">
<select
               name="selectedTemplate"
               onChange={this.handleOnTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templates.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>
<input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subjectUpdate} name="subjectUpdate" placeholder="Subject of your Update" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
<ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
<div className="text-center"> <div className="btn btn-primary send-button" onClick={()=>this.sendMail('update')} placeholder="Attachment">Send</div></div>



</div>

</Modal>
<tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Update
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.updates!=undefined ? this.state.updates.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"/assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           )):''}
         </ul>


       </tr>
       </div>
     </div></div> : ''}



      </div>    
    );
  }
}
