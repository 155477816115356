import React, { Component } from "react";
import "../../style/panel.css";
import { BrowserRouter, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";
/* 
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js"; */
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import BreadCrumbs from "../BreadCrumbs";

import addWorkflow from "./addWorkflow";
import addSmartDoc from "./addSmartDoc";
import SidebarWorkflow from "./SidebarWorkflow";
import viewWorkflow from "./viewWorkflow";
import pageWorkflow from "./pageWorkflow";

import Dashboard from "./Dashboard";
import Header from "../header";
import pageDoc from "./pageDoc";
import ViewSmartDoc from "./viewSmartDoc";
import SmartDocDashboard from "./smartDocDashboard";
import SmartDocTemplates from "./smartDocTemplates";
const data = [];
export default class SmartDocMainapp extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
    };
  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;

    $(function () {
      $("#tab1").click(function (e) {
        $(e.currentTarget).parent().toggleClass("sidebar-secondary");
        $(e.currentTarget).find("i:first").toggleClass("sidebar-back-arrow");
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainDiv">
        <Header/>
        
        <div className="row">
          <div className="col-md-2">
            <SidebarWorkflow title={this.state.title} />
          </div>
          <div className="col-md-10">
            <div className="section">
             

              <Route exact path="/organisations/:organisationName/smartdoc/add" component={addSmartDoc} />
              {/* SMARTDOC ID : ID,  SMARTDOC VERSION : SDV  */}
              <Route exact path="/organisations/:organisationName/smartDoc/view/:id/" component={pageDoc} />
              <Route exact path="/organisations/:organisationName/smartDoc/view" component={ViewSmartDoc} />
              <Route exact path="/organisations/:organisationName/smartdoc" component={SmartDocDashboard} />
              <Route
                exact
                path="/organisations/:organisationName/smartdoc/templates"
                component={SmartDocTemplates}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
