
import { Route } from "react-router-dom";


import React, { Component } from "react";
import Calculator from "./Calculator/Calculator";
import Sidebar from "./Sidebar";

import InputCalculator from "./Calculator2/InputCalculator";
import DayCount from "./DaysCalculator/DayCount";


export default class CalculatorMainapp extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <div className="mainDiv" >
            <div className="row">
            <div className="col-md-2">
                <Sidebar/>
            </div>
            <div className="col-md-10">
            <div className="section">
          
            <Route exact path="/organisations/:organisationName/tools/calculator" component={Calculator}/>
            <Route exact path="/organisations/:organisationName/tools/calculator/calc1" component={Calculator}/>
            <Route exact path="/organisations/:organisationName/tools/calculator/calc2" component={InputCalculator}/>
            <Route exact path="/organisations/:organisationName/tools/calculator/days-calc" component={DayCount}/>
            </div> 
            </div> 
            </div>
           
            
            </div>
        )
    }
}


