import React from "react";
import { useState, useEffect } from "react";
import './Calendar.css';
import './CalendarHome.css';
import axios from "axios";
import { apiBaseUrl1, apiBaseUrl2 } from "../../apiUtils";
export default function CalendarImages(props) {

  // make an array of default images with the following keys. You can change the key-value pairs as per your comfort.
const [calImages, setCalImages] = useState(

  [
    {
      name: "January",
      id: "1",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },
    {
      name: "February",
      id: "2",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },
    {
      name: "March",
      id: "3",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },
    {
      name: "April",
      id: "4",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },
    {
      name: "May",
      id: "5",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },
    {
      name: "June",
      id: "6",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },{
      name: "July",
      id: "7",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },{
      name: "August",
      id: "8",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },{
      name: "September",
      id: "9",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },{
      name: "October",
      id: "10",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },{
      name: "November",
      id: "11",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    },{
      name: "December",
      id: "12",
      imageUrl:
        "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    }

  ]);


const [calId, setCalId]=useState(props.calendarId)
const [selectedMonth, setSelectedMonth]=useState(props.clickedMonth)

//for encoding our image file
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  useEffect(()=>{
   setSelectedMonth(props.clickedMonth)
  },[props.clickedMonth])



  useEffect(()=>{
      getCalendarImages();
  },[])

  const postCalImages=(calImages)=>{
  

    const apiUrl = localStorage.isSp=='true' ? `${apiBaseUrl1}businus/serviceProvider/update/${localStorage.userId}`
    :  `${apiBaseUrl2}businus/employee/update/${localStorage.userId}`;

    axios
    .post(
      apiUrl,
      {calImages:calImages})
  .then((res)=>
  {
    console.log('success');
    localStorage.setItem("calendarImages", JSON.stringify(calImages));
  
  })
  .catch((err)=>
  {
    console.log("error occured")
  })

  }



  const getCalendarImages = ()=>{
    const apiUrl = localStorage.isSp=='true' ? 
    `${apiBaseUrl1}businusquery/getServiceProviderById/${localStorage.userId}`
    : 
    `${apiBaseUrl2}businusquery/getEmployeeById/${localStorage.userId}`;

    axios
    .get(apiUrl
    ).then((res)=>
    {
      if(res.data && res.data.calImages){
        
        setCalImages([...res.data.calImages])
      }
    
    })
    .catch((err)=>
    {
      console.log("error occured")
    })
  }



  const uploadHandler = async (e, imageKey) => {
    debugger;
    
      const fileOut = e.target.files[0];
    const file = await getBase64(fileOut);

    axios
      .post(
        "https://uv2ucx62n8.execute-api.us-east-1.amazonaws.com/dev/businusquery/uploadCalImages",
        { uploadImage: file, imageName: fileOut.name }
      )
      .then((response) => {

        if (response.status === 200) {
          let tempArray = calImages;
    
         let found= tempArray.find((item,i)=>{
          return item.id==imageKey;
        })
        
        found.imageUrl=response.data.Location;
        setCalImages([...tempArray]);
     
       if(calImages){
         postCalImages(calImages)
       }
      } 
      })
      .catch((err) => {
        console.log(err);
      });
  };
//import bootstrap css if you wish to use this code. Else style it the way you've styled the entire module.
  return (
    <>
  
    {
     calImages.map((item, i)=>{

       if(item.name==selectedMonth){
       
      return ( <>
      <div className="calendar_sec1_image_holder">
        <label for="image-input" className="calendar_sec1_image_holder">
        <img className="calendar-images" src={item.imageUrl} alt="" />
        </label>
        </div>
        <div >
      <input
      className="calImages-inputs"
      type="file"
      id="image-input"
      onChange={(e) => {
        uploadHandler(e, item.id);
      }}
      />
      </div>
      </>
     )}})
    }




       
    
            
    </>

      
    
  );
}