import React, { Component } from "react";
import axios from 'axios';
import { apiBaseUrl1 } from '../apiUtils';
import { apiBaseUrl2, getHeader } from '../apiUtils';
import { Link } from "react-router-dom";
import $ from "jquery";
import BreadCrumbs from "../BreadCrumbs";

import 'react-table/react-table.css'
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{},
    count:'',
 
    };

    context = this;
  }
  componentDidMount(){
    //sessionStorage.setItem("email","gautammegha2@gmail.com");
  this.countQueries();

}


  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(){

    axios.get('https://0i22oiysad.execute-api.us-east-1.amazonaws.com/live/businusquery/listMailByTypeForClient/all/all/'+ sessionStorage.orgId +'/'+sessionStorage.email  )
    .then(function (response) {
      console.log(response.data);
      var threads = response.data ;
      var documents = _.where(threads, { docType: "Document" });
      var proposals = _.where(threads, { docType: "Proposal" });
      var mails = _.where(threads, { docType: "Mail" });
      var mails2 = _.where(threads, { docType: "mail" });
      var updates = _.where(threads, { docType: "Update" });
     
      context.setState({countDoc:documents.length});
      context.setState({countProposals:proposals.length});
      context.setState({countMails:mails.length});
      context.setState({countUpdates:updates.length});
      context.setState({counters:response.data.queries});
      context.setState({sendQueryCounter:response.data.category});
    }, getHeader())
    .catch(function (error) {
      console.log(error);
    });
  
  }

  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div >
      
      <div className="row breadcrumb1-row" style={{marginLeft:'0px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
      <div className="DashboardDiv" style={{paddingLeft:'0px',overflowY:'scroll'}} >
        
      
      <div className="row " style={{marginLeft:'20px'}}>
      <h2 className="dashboard-head">Dashboard</h2>
      </div>
   
           
            <tr/>
            <div className="row" style={{marginLeft:'20px'}}>
        <div className="col-md-6">
          <Link to={"/organisations/"+sessionStorage.orgName+"/communication/view/recieved/mail"}>
          
            
          <div className="card-counter primary">
          <div className="icon-carddd"> <i className="fa fa-envelope" /></div>
          
            <span className="count-numbers">{this.state.countMails ? this.state.countMails : "0"}</span>
            <span className="count-name">Messages </span>
          </div>
         
          </Link>
        </div>
        <div className="col-md-6">
          <Link to={"/organisations/"+sessionStorage.orgName+"/communication/send/mail"}>
          <div className="card-counter primary">
            
            <i className="fa fa-plus" />
            
            <span className="count-name">Send Message</span>
          </div>
          </Link>
        </div>
       
       
        
        </div>

        <div className="row" style={{marginLeft:'20px'}}>
      
            </div>
            <div className="row" style={{marginLeft:'20px'}}>
        <div className="col-md-6">
          <Link to={"/organisations/"+sessionStorage.orgName+"/communication/view/proposal"}>
          <div className="card-counter primary">
          <div className="icon-carddd"> <i className="fa fa-file" /></div>
           
            <span className="count-numbers">{this.state.countProposals ? this.state.countProposals : "0"}</span>
            <span className="count-name">Proposal </span>
          </div>
          </Link>
        </div>
       {/*<div className="col-md-6">
          <Link to="/communication-master/send/proposal">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Send Proposal</span>
          </div>
          </Link>
    </div>*/}
       
       
        
        </div>
        <div className="row" style={{marginLeft:'20px'}}>
      
            </div>
            <div className="row" style={{marginLeft:'20px'}}>
        <div className="col-md-6">
          <Link to={"/organisations/"+sessionStorage.orgName+"/communication/view/document"}>
          <div className="card-counter primary">
          <div className="icon-carddd"> <i className="fa fa-folder" /></div>
            
            <span className="count-numbers">{this.state.countDoc ? this.state.countDoc : "0"}</span>
            <span className="count-name">Documents </span>
          </div>
          </Link>
  </div>
        {/* <div className="col-md-6">
          <Link to="/communication-master/send/document">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Send Documents</span>
          </div>
          </Link>
</div>*/}
       
       
        
        </div>
        <div className="row" style={{marginLeft:'20px'}} >
        
            </div>
            <div className="row" style={{marginLeft:'20px'}}>
        <div className="col-md-6">
          <Link to={"/organisations/"+sessionStorage.orgName+"/communication/view/update"}>
          <div className="card-counter primary">
          <div className="icon-carddd"> <i className="fa fa-bell" /></div>
           
            <span className="count-numbers">{this.state.countUpdates ? this.state.countUpdates : "0"}</span>
            <span className="count-name">Updates</span>
          </div>
          </Link>
</div>
       {/* <div className="col-md-6">
          <Link to="/communication-master/send/update">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Send Update</span>
          </div>
          </Link>
</div>*/}
       
       
        
        </div>
        </div>
       
       
         
        
     

        


    
            </div>
    );
  }
}
