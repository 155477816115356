import React, { useState, useEffect } from 'react'
import "./Calculator.css"
import { BiRupee } from 'react-icons/bi';
import { BsPercent } from 'react-icons/bs';

function Calculator() {
    const [showProfit, setShowProfit] = useState(true);
    const [displayGST, setDisplayGST] = useState(0);
    const [gstAmt, setGstAmt] = useState(0);
    const [gstRate, setGstRate] = useState(5);
    const [profit, setProfit] = useState(0);
    const [totalGST, setTotalGst] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [gstType, setGstType] = useState(true);

    const calculateGST = (gst, pro, rate, display, totalGST, totalProfit) => {

        if (gstType) {
            // console.log("This is exclusive !")
            const gstCalculated = parseFloat(gstAmt) + (parseFloat(gstAmt) * parseFloat(gstRate) / 100);
            const gstWithProfit = (parseFloat(gstCalculated) * parseFloat(profit)) / 100;
            setDisplayGST(parseFloat(parseFloat(gstCalculated) + parseFloat(gstWithProfit)).toFixed(2));

            const totalG = ((rate * gst) / 100) + (pro * ((rate * gst) / 100) / 100);
            setTotalGst(parseFloat(totalG).toFixed(2));

            const totalP = display - gst - totalG;
            setTotalProfit(parseFloat(totalP).toFixed(2));

            // console.log(totalGST);
            // console.log(totalProfit);

            // console.log("============================");
            // console.log("Gst input : " + gst);
            // console.log("Gst display : " + display);
            // console.log("Profit percentage : "+ pro);
            // console.log("Gst rate: " + rate);
            // console.log("============================");
        } else {
            // console.log("This is inclusive !")
            // GST Amount = Original Cost – (Original Cost * (100 / (100 + GST% ) ) )

            const gstCalculated = parseFloat(gstAmt)-(parseFloat(gstAmt)*(100/(100+gstRate)));
            setDisplayGST(parseFloat(gstAmt)-parseFloat(gstCalculated));
            setTotalGst(parseFloat(parseFloat(gst)-parseFloat(display)).toFixed(2));
            console.log(displayGST);

        }
    }

    useEffect(() => {
        calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
    }, [gstAmt, profit, gstRate, displayGST, totalGST, totalProfit])

    return (
        <div className="calculator_container">
            <div className="calculator_innercontainer">
                <div className="calculator_inputs">
                    <div className="calculator_header">
                        <h1>GST Calculator</h1>
                        <p>The easiest way for businesses to calculate their GST</p>
                    </div>
                    <div className="gst_option">
                        <>
                            <input type="radio" id="exclusive" name="gst_type" value="exclusive"
                                onChange={(e) => {
                                    setGstType(true);

                                    console.log(gstType)
                                }}
                            />
                            <label htmlFor="exclusive">Exclusive of GST</label>
                        </>
                        <>
                            <input type="radio" id="inclusive" name="gst_type" value="inclusive"
                                onChange={(e) => {
                                    setGstType(false);
                                    console.log(gstType)
                                }}

                            />
                            <label htmlFor="inclusive" >Inclusive of GST</label>
                        </>
                    </div>
                    <div className="calculator_takeinputs">
                        <p><span>Cost of Goods / Services </span></p>
                        <div className="takeinput takeinput_one">
                            <BiRupee size={30} />
                            <input type="number" name="gst" id="gstval" placeholder="Enter Amount Here"
                                onChange={(e) => {
                                    setGstAmt(parseFloat(e.target.value));
                                    calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                                }}
                            />
                        </div>
                        {showProfit ?
                            <div className="show_profit">
                                {gstType ?
                                    <p onClick={() => {
                                        showProfit ? setShowProfit(false) : setShowProfit(true);
                                    }}>+ Add Profit Ratio</p> : <div></div>
                                }
                            </div> :
                            <>
                                <p><span>Profit Ratio</span></p>
                                <div className="takeinput takeinput_two">
                                    <input type="number" name="profit" id="profitpercentage" placeholder="Enter Profit Ratio Here"
                                        onChange={(e) => {
                                            setProfit(e.target.value);
                                            calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                                        }}
                                    />
                                    <BsPercent size={30} />
                                </div>
                            </>
                        }
                    </div>
                    <div className="calculator_rates">
                        <p><span>Select GST Rate</span></p>
                        <div className="gst_rates">
                            <button onClick={(e) => {
                                setGstRate(3);
                                calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                            }} className={gstRate === 3 ? "button_active" : "button_inactive"}>3%</button>
                            <button onClick={(e) => {
                                setGstRate(5);
                                calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                            }} className={gstRate === 5 ? "button_active" : "button_inactive"}>5%</button>
                            <button onClick={(e) => {
                                setGstRate(12);
                                calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                            }} className={gstRate === 12 ? "button_active" : "button_inactive"}>12%</button>
                            <button onClick={(e) => {
                                setGstRate(18);
                                calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                            }} className={gstRate === 18 ? "button_active" : "button_inactive"}>18%</button>
                            <button onClick={(e) => {
                                setGstRate(28);
                                calculateGST(gstAmt, profit, gstRate, displayGST, totalGST, totalProfit);
                            }} className={gstRate === 28 ? "button_active" : "button_inactive"}>28%</button>

                        </div>
                    </div>
                </div>
                <div className="calculator_display">
                    <div className="image_holder">
                        <div className="display_price">
                            {isNaN(parseFloat(displayGST)) ? parseFloat(0).toFixed(2) : parseFloat(displayGST).toFixed(2)}
                        </div>

                        <div className="display_price_inner">
                            <div className="total_profit">
                                ₹ {isNaN(Math.abs(totalProfit).toFixed(2)) ? parseFloat(0).toFixed(2) : (Math.abs(totalProfit).toFixed(2))}
                            </div>
                            <div className="total_gst">
                                ₹ {isNaN(totalGST) ? parseFloat(0).toFixed(2) : totalGST}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Calculator